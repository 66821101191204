import Vue from 'vue'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

Vue.config.productionTip = false

Vue.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 2000,
  toastClassName: 'ghd',
  bodyClassName: ['ghd'],
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  beforeCreate() { this.$store.commit('user/initialiseStoreRequestDeletetingCode') },
  render: h => h(App),
}).$mount('#app')
