import CryptoJS from 'crypto-js'

export const getDecryptedDataFromLocalStorage = index => {
  if (localStorage.getItem(index)) {
    return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem(index), process.env.VUE_APP_CRYPT).toString(CryptoJS.enc.Utf8))
  }

  return null
}

export const cryptDataIntoLocalStorage = (index, data) => {
  localStorage.setItem(index, CryptoJS.AES.encrypt(data, process.env.VUE_APP_CRYPT).toString())
}
