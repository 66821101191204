import axios from '@axios'

export default {
  namespaced: true,
  state: {
    assistances: null,
    assistance: [],
    stepProducts: [],
    searchedOrder: [],
    startAssistance: false,
    activeStep: 1,
    textMessages: {
      headerMessage: 'Informazioni generali',
      subHeaderMessage: '',
    },
  },
  getters: {
    getAssistances: state => state.assistances,
    getAssistance: state => state.assistance,
    getStepProducts: state => state.stepProducts,
    getSearchedOrder: state => state.searchedOrder,
    getTextMessages: state => state.textMessages,
    getStartAssistance: state => state.startAssistance,
    getActiveStep: state => state.activeStep,
  },
  mutations: {
    setAssistances: (state, assistances) => {
      state.assistances = assistances
    },
    setAssistance: (state, assistance) => {
      state.assistance = assistance
    },
    setStepProducts: (state, stepProducts) => {
      state.stepProducts = stepProducts
    },
    setSearchedOrder: (state, searchedOrder) => {
      state.searchedOrder = searchedOrder
    },
    setHeaderTextMessages: (state, headerMessage) => {
      state.textMessages.headerMessage = headerMessage
    },
    setSubHeaderTextMessages: (state, subHeaderMessage) => {
      state.textMessages.subHeaderMessage = subHeaderMessage
    },
    setStartAssistance: (state, startAssistance) => {
      state.startAssistance = startAssistance
    },
    setActiveStep: (state, activeStep) => {
      state.activeStep = activeStep
    },
  },
  actions: {
    fetchAssistances(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/assistenza', data)
          .then(response => {
            ctx.commit('setAssistances', null)
            ctx.commit('setAssistances', response.data.data.assistenze)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAssistance(ctx, data) {
      ctx.commit('setAssistance', [])

      return new Promise((resolve, reject) => {
        axios
          .get(`/api/assistenza/${data}`)
          .then(response => {
            ctx.commit('setAssistance', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    storeAssistance(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/assistenza', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchStepProducts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/product-step', data)
          .then(response => {
            ctx.commit('setStepProducts', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, data) {
      ctx.commit('setSearchedOrder', [])

      return new Promise((resolve, reject) => {
        axios
          .get('/api/search-items', { params: data })
          .then(response => {
            ctx.commit('setSearchedOrder', response.data.data)
            resolve(response)
          })
          .catch(error => {
            ctx.commit('setSearchedOrder', 'empty')
            reject(error)
          })
      })
    },
    updateAssistance(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/assistenza/${data.code}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    storePickup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/assistenza/${data.ass_code}/pickup`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    deletePickup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/pickup/${data.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    trackingPickup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/pickup/${data.id}/tracking`, {})
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    downloadEtichetta(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/generate-files', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
