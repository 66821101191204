import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import userStoreModule from '@/store/userStoreModule'
import app from './app'
import assistanceStoreModule from '@/store/assistanceStoreModule'
import globalStoreModule from '@/store/globalStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user: userStoreModule,
    assistance: assistanceStoreModule,
    global: globalStoreModule,
  },
})
