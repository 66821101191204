<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="ms-4"
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            class="text-gold"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </div>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.avatar"
              src="@/assets/images/avatars/1.png"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3 mr-5"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1 text-sm">
            {{ userData.email || userData.username }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.role }}</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <v-list-item
        v-if="verticalMode"
        @click="$router.push({name: 'assistenza-searchitem'})"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiPlus }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm">
            Apri pratica di assistenza
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="$router.push({ name:'user-profile' })"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm">
            Profilo
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="$router.push({ name:'dashboard' })"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiBookOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm">
            Le tue assistenze
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="$router.push({ name:'user-manage-addresses' })"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiClipboardOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm">
            Gestione indirizzi
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="openPrivacyInNewTab()"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiShieldAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm">
            Privacy
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm">
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiCheckOutline,
  mdiClipboardOutline,
  mdiShieldAccountOutline,
  mdiPlus,
  mdiBookOutline,

} from '@mdi/js'
import { useRouter } from '@core/utils'
import { getCurrentInstance } from '@vue/composition-api'
import { getDecryptedDataFromLocalStorage } from '@core/utils/crypt'
import { initialAbility } from '@/plugins/acl/config'

export default {
  props: {
    verticalMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = getDecryptedDataFromLocalStorage('userData')

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
      window.location.reload()
    }

    const openPrivacyInNewTab = () => {
      window.open(router.resolve({ name: 'privacy' }).href, '_blank')
    }

    return {
      logoutUser,
      userData,
      openPrivacyInNewTab,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiCheckOutline,
        mdiClipboardOutline,
        mdiShieldAccountOutline,
        mdiPlus,
        mdiBookOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
