<template>
  <div>
    <router-link :to="{name: 'assistenza-searchitem'}">
      <span
        class="text-decoration-none ghd-text-white ghd-ITCAvant"
      >APRI PRATICA ASSISTENZA</span>
    </router-link>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiArrowRightDropCircleOutline } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRightDropCircleOutline,
      },
    }
  },
}
</script>
