import axios from '@axios'

export default {
  namespaced: true,
  state: {
    addresses: [],
    canRequestDeletingCode: true,
  },
  getters: {
    getAddresses: state => state.addresses,
    getClients: state => state.clients,
    getCanRequestDeleteingCode: state => state.canRequestDeletingCode,
  },
  mutations: {
    setAddresses: (state, addresses) => {
      state.addresses = addresses
    },
    setCanRequestDeletingCode: (state, requested) => {
      localStorage.setItem('canRequestDeletingCode', requested)
      if (requested) {
        // se puo richiedere la cancellazione del tuo account non ha manco una data di scadenza
        localStorage.removeItem('exp_date_code_delacc')
        localStorage.removeItem('canRequestDeletingCode')
      }
      state.canRequestDeletingCode = requested
    },
    initialiseStoreRequestDeletetingCode(state) {
      if (localStorage.getItem('canRequestDeletingCode')) {
        if (localStorage.getItem('exp_date_code_delacc') > Date.now()) {
          state.canRequestDeletingCode = false
        } else {
          state.canRequestDeletingCode = true
          localStorage.removeItem('exp_date_code_delacc')
          localStorage.removeItem('canRequestDeletingCode')
        }
      } else {
        state.canRequestDeletingCode = true
      }
    },
  },
  actions: {
    storeUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/registrazione', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/user/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/generate/code/delete/account', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    // TODO da completare
    deleteUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/user/${data.user_id}`, { params: data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    verificateUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/verifica/account', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAddresses(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/ws/search/addresses', data)
          .then(response => {
            ctx.commit('setAddresses', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
