export default {
  namespaced: true,
  state: {
    errorMessages: [],
    breadcrumbs: [],
  },
  getters: {
    getErrorMessages: state => state.errorMessages,
    getBreadCrumbs: state => state.breadcrumbs,
  },
  mutations: {
    setErrorMessages: (state, messages) => {
      state.errorMessages = messages
    },
    setBreadCrumbs: (state, breadcrumbs) => {
      state.breadcrumbs = breadcrumbs
    },
  },
  actions: {},
}
