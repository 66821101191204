const registration = [
  {
    path: '/registration/:type',
    name: 'registration',
    component: () => import('@/views/registration/registration.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      isDark: false,
    },
  },
  {
    path: '/verification/:email?',
    name: 'verification',
    component: () => import('@/views/registration/registrationEmailVerification.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      isDark: false,
    },
  },
]

export default registration
