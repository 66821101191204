const dashboard = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/dashboardDataLoader.vue'),
    meta: {
      layout: 'content',
      action: 'index',
      resource: 'assistenza',
      isDark: false,
      headerMessage: 'LE TUE PRATICHE',
      breadCrumbs: ['HOME'],
    },
  },
]

export default dashboard
