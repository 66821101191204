<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template
      #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }"
    >
      <div
        class="navbar-content-container ghd-base-color-black"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center w-full ml-4">
          <div>
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <div>
                <router-link
                  to="/"
                  class="m-2"
                >
                  <img
                    height="auto"
                    width="85px"
                    :src="appLogo"
                  />
                </router-link>
              </div>
            </router-link>
          </div>

          <div class="ml-auto mr-auto ghd-text-white text-uppercase header text-center">
            {{ getHeaderText() }}
          </div>

          <!--          <v-icon-->
          <!--            v-if="$vuetify.breakpoint.mdAndDown"-->
          <!--            class="me-3"-->
          <!--            @click="toggleVerticalNavMenuActive"-->
          <!--          >-->
          <!--            {{ icons.mdiMenu }}-->
          <!--          </v-icon>-->
          <!--          <app-bar-search-->
          <!--            :shall-show-full-search.sync="shallShowFullSearch"-->
          <!--            :data="appBarSearchData"-->
          <!--            :filter="searchFilterFunc"-->
          <!--            :search-query.sync="appBarSearchQuery"-->
          <!--            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"-->
          <!--          ></app-bar-search>-->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!--          <app-bar-i18n></app-bar-i18n>-->
          <!--          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>-->
          <!--          <app-bar-notification></app-bar-notification>-->
          <app-bar-user-menu :vertical-mode="true"></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>{{ stringBreadCrumbs }}</span>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>

// App Bar Components
// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
// import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'

// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
import { ref, watch, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import appBarSearchData from '@/assets/app-bar-search-data'
import navMenuItems from '@/navigation/vertical'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import globalService from '@/services/globalService'
import assistanceService from '@/services/assistanceService'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    // AppBarSearch,
    // AppBarI18n,
    // AppBarThemeSwitcher,
    AppBarUserMenu,

    // AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const {
      getHeaderText,
    } = assistanceService()

    const {
      getBreadCrumbs,
    } = globalService()

    const stringBreadCrumbs = computed(() => {
      let breadCrumbs = ''
      getBreadCrumbs().forEach(item => {
        breadCrumbs += `${item} / `
      })

      return breadCrumbs.substring(0, breadCrumbs.length - 2)
    })

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,
      getHeaderText,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      stringBreadCrumbs,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
