import {
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'

export default () => {
  const currentAssistance = ref(null)

  const isCompleted = data => {
    let esito = null
    if (data) {
      esito = data.stato === 'GAR'
    }

    return {
      status: esito,
      color: esito ? 'success' : 'error',
      icon: esito ? mdiCheckboxMarkedCircleOutline : mdiCloseCircleOutline,
    }
  }

  const filterByNeedScenario = products => products.filter(product => product.need_scenario)

  const fetchAssistances = () => {
    store.dispatch('assistance/fetchAssistances', {})
  }

  const fetchAssistance = data => {
    store.dispatch('assistance/fetchAssistance', data)
  }

  const fetchStepProducts = () => {
    store.dispatch('assistance/fetchStepProducts', {})
  }

  const handleStorePickup = data => store.dispatch('assistance/storePickup', data)
  const handleDeletePickup = data => store.dispatch('assistance/deletePickup', data)
  const handleTrackingPickup = data => store.dispatch('assistance/trackingPickup', data)
  const handleDownloadEtichetta = data => store.dispatch('assistance/downloadEtichetta', data)

  const getAssistances = () => store.getters['assistance/getAssistances']
  const getAssistance = () => store.getters['assistance/getAssistance']
  const getStepProdcuts = () => store.getters['assistance/getStepProducts']
  const getSearchedOrder = () => store.getters['assistance/getSearchedOrder']
  const getHeaderText = () => store.getters['assistance/getTextMessages'].headerMessage
  const getSubHeaderText = () => store.getters['assistance/getTextMessages'].subHeaderMessage
  const getStartAssistance = () => store.getters['assistance/getStartAssistance']
  const getActiveStep = () => store.getters['assistance/getActiveStep']

  watch(() => getAssistance(), () => {
    currentAssistance.value = getAssistance()
  })

  return {
    currentAssistance,
    isCompleted,
    filterByNeedScenario,
    fetchAssistances,
    fetchAssistance,
    fetchStepProducts,
    getAssistances,
    getAssistance,
    getStepProdcuts,
    getSearchedOrder,
    getHeaderText,
    getSubHeaderText,
    getStartAssistance,
    getActiveStep,
    handleStorePickup,
    handleDeletePickup,
    handleTrackingPickup,
    handleDownloadEtichetta,
  }
}
