<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>
    <!-- Navbar -->
    <template #navbar>
      <div
        class="navbar-content-container ghd-base-color-black"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <div>
              <router-link
                to="/"
                class="m-2"
              >
                <img
                  height="auto"
                  width="85px"
                  :src="appLogo"
                />
              </router-link>
            </div>
          </router-link>
        </div>
        <h2 class="ml-auto mr-auto ghd-text-white text-uppercase header">
          {{ getHeaderText() }}
        </h2>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <template v-if="$route.name === 'dashboard'">
            <assistenza-create-button></assistenza-create-button>
          </template>
          <!--          <app-bar-search-->
          <!--            :shall-show-full-search.sync="shallShowFullSearch"-->
          <!--            :data="appBarSearchData"-->
          <!--            :filter="searchFilterFunc"-->
          <!--            :search-query.sync="appBarSearchQuery"-->
          <!--            class="me-4"-->
          <!--          ></app-bar-search>-->
          <!--          <app-bar-i18n></app-bar-i18n>-->
          <!--          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>-->
          <!--          <app-bar-notification></app-bar-notification>-->
          <app-bar-user-menu class="ms-2"></app-bar-user-menu>
        </div>
      </div>

      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>
    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>{{ stringBreadCrumbs }}</span>
      </div>
    </template>
  </layout-content-horizontal-nav>
</template>

<script>
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'

// App Bar Components
// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
// import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'

// Search Data

import { ref, watch, computed } from '@vue/composition-api'

import themeConfig from '@themeConfig'
import { mdiHeartOutline } from '@mdi/js'
import AssistenzaCreateButton from '@/views/dashboard/AssistenzaCreateButton.vue'
import appBarSearchData from '@/assets/app-bar-search-data'
import navMenuItems from '@/navigation/horizontal'
import assistanceService from '@/services/assistanceService'
import globalService from '@/services/globalService'

export default {
  components: {
    LayoutContentHorizontalNav,

    // App Bar Components
    // AppBarSearch,
    // AppBarI18n,
    // AppBarThemeSwitcher,
    AppBarUserMenu,

    AssistenzaCreateButton,

    // AppBarNotification,
  },
  setup() {
    const {
      getHeaderText,
    } = assistanceService()

    const {
      getBreadCrumbs,
    } = globalService()

    const stringBreadCrumbs = computed(() => {
      let breadCrumbs = ''
      getBreadCrumbs().forEach(item => {
        breadCrumbs += `${item} / `
      })

      return breadCrumbs.substring(0, breadCrumbs.length - 2)
    })

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    return {
      navMenuItems,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      stringBreadCrumbs,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },

      getHeaderText,
      getBreadCrumbs,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
