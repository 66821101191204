// axios
import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import { initialAbility } from '@/plugins/acl/config'
import router from '@/router'
import ability from '@/plugins/acl/ability'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_APIURL}`,

  // withCredentials: true,

  // timeout: 1000,
  // headers: { 'Access-Control-Allow-Origin:': '*' },
})
const logoutUser = () => {
  // Remove userData from localStorage
  // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem('accessToken')

  // Remove userData & Ability from localStorage
  localStorage.removeItem('userData')
  localStorage.removeItem('userAbility')

  ability.update(initialAbility)

  router.push({ name: 'auth-login' })
  window.location.reload()
}

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(response => {
  if (response.status === 200 && response.data.message) {
    Vue.$toast(response.data.message, {
      timeout: 2000,
    })
  }

  return response
}, err => {
  if (err.response.status === 422) {
    const firstKey = Object.keys(err.response.data.data)[0]

    store.commit('global/setErrorMessages', err.response.data.message)

    Vue.$toast(err.response.data.data[firstKey][0], {
      timeout: 3000,
    })
  }
  if (err.response.status === 401 || err.response.status === 404 || err.response.status === 403 || err.response.status === 500) {
    Vue.$toast(err.response.data.message, {
      timeout: 3000,
    })

    if (err.response.status === 401) {
      logoutUser()
    }
  }

  return Promise.reject(err)
})

Vue.prototype.$http = axiosIns

export default axiosIns
