const dashboard = [
  {
    path: '/assistenza/create',
    name: 'assistenza-create',
    component: () => import('@/views/assistance/AssistenzaCreate.vue'),
    meta: {
      layout: 'content',
      action: 'store',
      resource: 'assistenza',
      headerMessage: 'INSERISCI IL TUO PRODOTTO E APRI LA PRATICA DI ASSISTENZA',
      subHeaderMessage: 'Seleziona la tipologia e registra il tuo prodotto',
      breadCrumbs: ['Home', 'Assistenza'],
    },
  },
  {
    path: '/assistenza/searchitem',
    name: 'assistenza-searchitem',
    component: () => import('@/views/assistance/AssistenzaSearchItem.vue'),
    meta: {
      layout: 'content',
      action: 'store',
      resource: 'assistenza',
      headerMessage: 'INSERISCI IL SERIALE DEL TUO PRODOTTO',
      subHeaderMessage: 'INSERISCI IL SERIALE DEL TUO PRODOTTO PER APRIRE UNA PRATICA D\'ASSISTENZA',
      breadCrumbs: ['Home', 'Cerca ordine'],
    },
  },
]

export default dashboard
