import Vue from 'vue'
import VueRouter from 'vue-router'
import { getDecryptedDataFromLocalStorage } from '@core/utils/crypt'
import { canNavigate } from '@/plugins/acl/routeProtection'
import registration from '@/router/registration'
import dashboard from '@/router/dashboard'
import assistance from '@/router/assistance'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/homepage/homePage.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/maintenance.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/reset-password/:token?',
    name: 'auth-reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/user/addresses',
    name: 'user-manage-addresses',
    component: () => import('@/views/user/UserAddresses.vue'),
    meta: {
      layout: 'content',
      action: 'index',
      resource: 'address',
      headerMessage: 'INDIRIZZI',
      breadCrumbs: ['Home', 'Indirizzi'],

    },
  },
  {
    path: '/user/profile',
    name: 'user-profile',
    component: () => import('@/views/user/UserProfile.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      headerMessage: 'PROFILO',
      breadCrumbs: ['Home', 'Profilo'],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  ...registration,
  ...dashboard,
  ...assistance,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
const maintenance = process.env.VUE_APP_MAINTENANCE_MODE
// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = getDecryptedDataFromLocalStorage('userData')

  const isLoggedIn = userData && localStorage.getItem('accessToken') && getDecryptedDataFromLocalStorage('userAbility')
  if (maintenance === 'true' && to.name !== 'maintenance') {
    return next({ name: 'maintenance' })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  store.commit('global/setErrorMessages', [])

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/dashboard')
  }

  if (to.meta && to.meta.headerMessage) {
    store.commit('assistance/setHeaderTextMessages', to.meta.headerMessage)
  }

  if (to.meta && to.meta.subHeaderMessage) {
    store.commit('assistance/setSubHeaderTextMessages', to.meta.subHeaderMessage)
  }

  if (to.meta && to.meta.breadCrumbs) {
    store.commit('global/setBreadCrumbs', to.meta.breadCrumbs)
  }

  return next()
})

export default router
